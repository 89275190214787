<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { ProductGridItem } from 'gig/types/product-grid-item'

defineProps<{
  items: ProductGridItem[]
  heading?: string
}>()

defineEmits<{
  (e: 'updateFavoriteProduct', id: string): void
}>()

const { sendClick, sendImpressions } = useGigImpressions()

const favoritesStore = useFavoritesStore()
const { gigIds } = storeToRefs(favoritesStore)

const updateFavoriteGig = async (id: string) => {
  await favoritesStore.updateFavorite(id, 'gig')
}
</script>

<template>
  <slot>
    <h2
      v-if="heading"
      class="mb-4 text-2xl lg:mb-8"
    >
      {{ heading }}
    </h2>
  </slot>
  <AppSlider
    prev-button-class="!bg-white"
    next-button-class="!bg-white"
    position-buttons-outside-of-container
  >
    <GigGridItem
      v-for="(item, key) in items"
      :key="key"
      class="slides-item my-1 snap-start"
      :product="item"
      :is-gig-favorite="gigIds.includes(item.id)"
      @update-favorite-product="updateFavoriteGig"
      @click="sendClick($event.target, item.id)"
      @intersecting="sendImpressions([item.id])"
    />
  </AppSlider>
</template>

<style scoped>
:deep(.slides) {
  --gap: 16px;
  gap: var(--gap);
}

.slides-item {
  --slides-number: 2;
  --visible-slides: 1.15;
  --spacing-per-slide: calc(var(--gap) * (var(--slides-number) - 1)) /
    var(--slides-number);
  flex-basis: calc((100% / var(--visible-slides)) - var(--spacing-per-slide));
  flex-grow: 0;
  flex-shrink: 0;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    margin-right: var(--gap);
  }

  @media (min-width: 400px) {
    --slides-number: 2;
    --visible-slides: 1.75;
  }

  @media (min-width: 640px) {
    --slides-number: 3;
    --visible-slides: 2.5;
  }

  @media (min-width: 768px) {
    --slides-number: 3;
    --visible-slides: 3;
  }

  @media (min-width: 1024px) {
    --slides-number: 4;
    --visible-slides: 4;
  }
}
</style>
