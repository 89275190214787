import type { ProductGridItem } from 'gig/types/product-grid-item'

export default function () {
  const sendClick = async (
    target: HTMLElement,
    productId: ProductGridItem['id'],
  ) => {
    if (
      ['p-button-favorite', 'pi-heart-fill', 'pi-heart'].some((className) =>
        target.classList.contains(className),
      )
    ) {
      return
    }

    try {
      const { error } = await useApi<void>('/metrics/click', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({ productId }),
      })

      if (error.value) {
        throw new Error(error.value.message)
      }
    } catch (error) {
      console.error(
        `Error while sending click impression. ${(error as Error).message}`,
      )
    }
  }

  const sendImpressions = async (products: Array<ProductGridItem['id']>) => {
    try {
      const { error } = await useApi<void>('/metrics/impressions', {
        method: 'post',
        body: products,
      })

      if (error.value) {
        throw new Error(error.value.message)
      }
    } catch (error) {
      console.error(
        `Error while sending product impressions. ${(error as Error).message}`,
      )
    }
  }

  return {
    sendClick,
    sendImpressions,
  }
}
